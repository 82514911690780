import { Box, Flex, Text } from '@chakra-ui/react'

import InputSection from './InputSection'

const BottomSection = ({
  setQuery,
  search,
  query,
  handleOpenWarningMessage,
}: {
  setQuery: React.Dispatch<React.SetStateAction<string>>
  search: (forceQuery?: string, _id?: string) => Promise<void>
  handleOpenWarningMessage: () => void
  query: string
}) => {
  return (
    <Box
      pos='absolute'
      bottom={'35px'}
      m='auto'
      left={'50%'}
      transform='translate(-50%,0)'
    >
      <Flex
        bg='#e9e7ef'
        borderTopRadius={'8px'}
        borderColor='#cfcce0'
        mx='8px'
        onClick={handleOpenWarningMessage}
        cursor='pointer'
        justify={'space-between'}
      >
        <Text fontSize={'xs'} px='1rem' py='4px' color={'#434032'}>
          Questions, answers, and feedback are logged. No other information is
          collected. All collected data is anonymized to protect your privacy.
        </Text>
        <Text
          fontSize={'xs'}
          px='1rem'
          py='4px'
          color={'#434032'}
          fontWeight='semibold'
          textDecoration={'underline'}
          whiteSpace='nowrap'
        >
          Read more.
        </Text>
      </Flex>
      <InputSection
        setQuery={setQuery}
        search={search}
        query={query}
        placeholder={`Search on Airfinity Data`}
      />
      <Text variant={'body3'} textAlign='center' mb={'-20px'} pt='4px'>
        Airfinity's AI can make mistakes. Consider checking the reports
        directly.
      </Text>
    </Box>
  )
}

export default BottomSection
